import firebase from 'firebase/app'

// Initialize Firebase
let config = {
  apiKey: "AIzaSyB7DDpi9wyMKQSGAG3P1DSvbnhCRWnwMF0",
  authDomain: "gcf-front.firebaseapp.com",
  projectId: "gcf-front",
  storageBucket: "gcf-front.appspot.com",
  messagingSenderId: "101778664844",
  appId: "1:101778664844:web:278409056cb635cda77aa9",
  measurementId: "G-YYXMZVL5FE"
}

firebase.initializeApp(config)
