export default {
  ADD_ITEM(state, item) {
    state.services.unshift(item)
  },
  SET_SERVICES(state, services) {
    state.services = services
  },
  UPDATE_SERVICE(state, service) {
    const couponIndex = state.services.findIndex((c) => c.id === service.id)
    Object.assign(state.services[couponIndex], service)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.services.findIndex((o) => o.id === itemId)
    state.services.splice(ItemIndex, 1)
  }
}
