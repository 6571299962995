export default {
  fetchServices({commit}, data) {
    commit('SET_SERVICES', data.services)
  },
  addService({commit}, data) {
    commit('ADD_ITEM', data.service)
  },
  updateService({commit}, data) {
    commit('UPDATE_SERVICE', data.service)
  },
}
