export default {
  ADD_POST(state, item) {
    state.posts.unshift(item)
  },
  SET_BLOGS(state, posts) {
    state.posts = posts
  },
  UPDATE_POST(state, post) {
    const postIndex = state.posts.findIndex((o) => o.id === post.id)
    Object.assign(state.posts[postIndex], post)
  }
}
