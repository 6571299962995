// en, de, fr, pt
export default {
  es: {
    Dashboard: 'Dashboard',
    ListClients: 'Listado',
    General: 'General',
    Users: 'Usuarios',
    Settings: 'Configuraciones',
    Content: 'Contenido',
    About: 'Nosotros',
    Members: 'Equipo',
    Partners: 'Partners',
    Services: 'Servicios',
    Industries: 'Industrias',
    Clients: 'Clientes',
    Posts: 'Posts'
  }
}

