export default {
  fetchPosts({commit}, data) {
    commit('SET_BLOGS', data.posts)
  },
  addPost({commit}, data) {
    commit('ADD_POST', data.post)
  },
  updatePost({commit}, data) {
    commit('UPDATE_POST', data.post)
  },
}
